import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ContactDto, ContactGroupsDto } from '@portal/shared/utils';

export interface Pageable<T> {
    content: T[];
    last: boolean;
    totalPages: number;
    totalElements: number;
    pageable: PageInfo;
    first: boolean;
    size: number;
    number: number;
    sort: {
        sorted: boolean;
        empty: boolean;
        unsorted: boolean;
    };
    numberOfElements: number;
    empty: boolean;
}

export interface PageInfo {
    pageNumber: number;
    pageSize: number;
    sort: {
        sorted: boolean;
        empty: boolean;
        unsorted: boolean;
    };
    offset: number;
    paged: boolean;
    unpaged: boolean;
}

export interface GroupToggle {
    groupId: number;
    contactId: number;
}

/**
 * @deprecated Should be replaced with ContactResourceController.
 */
@Injectable()
export class ContactController {
    private readonly api: HttpClient = inject(HttpClient);

    public getFilteredContacts(
        page: number,
        size: number,
        groupId: number,
        searchText: string | null = null,
        sort: string,
        direction: string
    ): Observable<Pageable<ContactDto>> {
        let params = new HttpParams();

        if (searchText) {
            params = params.set('searchText', searchText);
        }
        params = params.set('size', size);
        params = params.set('page', page);
        params = params.set('sort', sort);
        params = params.set('direction', direction);

        return this.api.get<Pageable<ContactDto>>(`contact/v1/contacts/by-group/${groupId}`, { params });
    }

    public getContactById(contactId: number | null): Observable<ContactDto> {
        return this.api.get<ContactDto>(`contact/v1/contacts/${contactId}`);
    }

    public createContact(contactForm: ContactDto): Observable<ContactDto> {
        return this.api.post<ContactDto>('contact/v1/contacts', contactForm);
    }

    public deleteContact(contactId: number): Observable<string> {
        return this.api.delete<string>(`contact/v1/contacts/${contactId}`);
    }

    public editContact(contact: ContactDto): Observable<ContactDto> {
        return this.api.put<ContactDto>(`contact/v1/contacts/${contact.contactId}`, contact);
    }

    public getAllGroups(): Observable<ContactGroupsDto[]> {
        return this.api.get<ContactGroupsDto[]>('contact/v1/groups');
    }

    public createGroup(group: ContactGroupsDto): Observable<ContactGroupsDto> {
        return this.api.post<ContactGroupsDto>('contact/v1/groups', group);
    }

    public deleteGroup(groupId: number): Observable<string> {
        return this.api.delete<string>(`contact/v1/groups/${groupId}`);
    }

    public renameGroup(group: ContactGroupsDto): Observable<ContactGroupsDto> {
        return this.api.put<ContactGroupsDto>(`contact/v1/groups/${group.id}`, group);
    }

    public addContactToGroup(group: GroupToggle): Observable<ContactDto> {
        return this.api.put<ContactDto>(`contact/v1/contacts/${group.contactId}/group/${group.groupId}`, null);
    }

    public removeContactFromGroup(group: GroupToggle): Observable<ContactDto> {
        return this.api.delete<ContactDto>(`contact/v1/contacts/${group.contactId}/group/${group.groupId}`);
    }
}

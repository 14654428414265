import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { LocationDto } from '@portal/shared/utils';

import { Pageable } from './contact.controller';

export enum UserRoles {
    DIRECTOR = 'DIRECTOR',
    PROVIDER = 'PROVIDER',
    MANAGER = 'MANAGER',
    OFFICE_ADMIN = 'OFFICE_ADMIN',
    TENANT_ADMIN = 'TENANT_ADMIN',
    ADMIN = 'ADMIN',
}

export interface UserDto {
    userId: number;
    email: string;
    firstName: string;
    middleName: string;
    lastName: string;
    password: string;
    roles: UserRoles[];
    active: boolean;
    created: number;
    updated: number;
    locked: number;
    sendNotification?: boolean;
    // audit:
    // locationId: number | null,
    // loginHistoryDto: [],
    // userLocationDto: [],
}

@Injectable()
export class UserController {
    private readonly api: HttpClient = inject(HttpClient);

    // Users

    public getAllUsers(page: number, size: number): Observable<Pageable<UserDto>> {
        const params = new HttpParams().set('page', page).set('size', size);
        return this.api.get<Pageable<UserDto>>('user/v1/users', { params });
    }

    public getUserById(userId: number | null): Observable<UserDto> {
        return this.api.get<UserDto>(`user/v1/users/${userId}`);
    }

    public createUser(user: UserDto): Observable<UserDto> {
        const sendNotification = user.sendNotification ?? false;
        const params = new HttpParams().set('sendNotification', sendNotification);
        return this.api.post<UserDto>('user/v1/users', user, { params });
    }

    public deleteUser(userId: number): Observable<string> {
        return this.api.delete<string>(`user/v1/users/${userId}`);
    }

    public toggleUserStatus(userId: number): Observable<UserDto> {
        return this.api.put<UserDto>(`user/v1/users/${userId}/active`, null);
    }

    public changeUserRole(user: UserDto): Observable<UserDto> {
        return this.api.put<UserDto>(`user/v1/users/${user.userId}/role`, user);
    }

    public resetUserPassword(user: UserDto): Observable<UserDto> {
        const sendNotification = user.sendNotification ?? false;
        const params = new HttpParams().set('sendNotification', sendNotification);
        return this.api.put<UserDto>(`user/v1/users/${user.userId}/reset-password`, user, { params });
    }

    public editUser(user: UserDto): Observable<UserDto> {
        return this.api.put<UserDto>(`user/v1/users/${user.userId}`, user);
    }

    // Locations

    public createLocation(locationDto: LocationDto): Observable<LocationDto> {
        return this.api.post<LocationDto>('location/v1/locations', locationDto);
    }

    public getAllLocations(page: number, size: number, listAll: boolean): Observable<Pageable<LocationDto>> {
        const params = new HttpParams().set('page', page).set('size', size).set('listAll', listAll);
        return this.api.get<Pageable<LocationDto>>('location/v1/locations', { params });
    }

    public getLocationById(locationId: number | null): Observable<LocationDto> {
        return this.api.get<LocationDto>(`location/v1/locations/${locationId}`);
    }

    public deleteLocation(locationId: number): Observable<string> {
        return this.api.delete<string>(`location/v1/locations/${locationId}`);
    }

    public editLocation(location: LocationDto): Observable<LocationDto> {
        return this.api.put<LocationDto>(`location/v1/locations/${location.locationId}`, location);
    }

    public toggleLocationStatus(locationId: number): Observable<LocationDto> {
        return this.api.put<LocationDto>(`location/v1/locations/${locationId}/active`, null);
    }

    public setNewHeadOffice(locationId: number): Observable<LocationDto> {
        return this.api.put<LocationDto>(`location/v1/locations/head-office/${locationId}`, null);
    }
}
